import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 3050px));
    padding: 0;
    margin: 30px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 13px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--white);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: whitescale(2%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--white);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(sourceInstanceName: { eq: "images" }, relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, traceSVG: { color: "#64ffda" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
  	'Data: Synthetic Data Generation, Data Pruning, Data Augmentation',
    'Trustworthy AI in the LLM era: Privacy, Hallucination, etc.',
    'Real-world applications of AI: AI for Life sciences, AI for Decision science, AI for Education'
  ];

  return (
    <StyledAboutSection id="About" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I am currently a Researcher at <a href="https://simons.berkeley.edu/homepage">Simons Institute (UC Berkeley)</a>, and a member of the <a href="https://deepfoundations.ai/">Collaboration on Theoretical Foundations of Deep Learning</a>. Previously, I obtained my PhD in Statistics & Machine learning at the <a href="https://www.stats.ox.ac.uk/">University of
              Oxford</a>. I was also a
              member of the <a href="http://csml.stats.ox.ac.uk/">OxCSML Group</a>. Before starting my PhD, I graduated from{' '}
              <a href="http://www.polytechnique.edu/">Ecole Polytechnique</a> in France with a major
              in Applied Mathematics (MSc and Engineering Diploma), I also have a MSc in Quantitative Finance from Paris VI University.
            </p>

            <p>
              I like to scale up things! My main research focuses on <font color="#48ff00"><b>efficient learning at scale</b></font>. My goal is to derive <font color="#48ff00"> <b>pareto-optimal algorithms for the pretraining and finetuning</b> </font> of large models.
               I am also interested in:
            </p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>

        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <Img fluid={data.avatar.childImageSharp.fluid} alt="Avatar" className="img" />
          </div>
        </StyledPic>

      </div>
      
    </StyledAboutSection>

  );
};

export default About;
